import React from "react"
import { Col, Row } from "reactstrap"

import "./ContraceptiveQuizCta.scss"
import Link from "../Link/Link"
import ExternalCondom from "../../images/external-condom.svg"

const ContraceptiveQuizCta = ({ standAlone }) => {
  return (
    <section
      className={standAlone ? "contraceptive-quiz margin-bottom padding" : "contraceptive-quiz"}
    >
      <section className="container">
        <Row>
          <Col xs={{ order: 2, size: 12 }} lg={{ order: 1, size: 6 }}>
            <section className="contraceptive-quiz__content">
              <h2 className="contraceptive-quiz__title">Contraception Quiz</h2>
              <p className="contraceptive-quiz__description">
                Not sure on the method? - Take our dynamic Contraception Quiz.{" "}
                <br />
                When it comes to sex, protection is as important as pleasure.
                But what should one do to start their safe sex journey? Answer
                some simple questions and based on the responses, we will
                recommend the next steps.
              </p>
              <Link
                to="/contraception-quiz"
                className="contraceptive-quiz__link button"
              >
                Take the quiz
              </Link>
            </section>
          </Col>

          <Col xs={{ order: 1, size: 12 }} lg={{ order: 2, size: 6 }}>
            <section className="contraceptive-quiz__image">
              <img
                className="contraceptive-quiz__image-src"
                src={ExternalCondom}
                alt="External Condom"
                loading="lazy"
              />
            </section>
          </Col>
        </Row>
      </section>
    </section>
  )
}

export default ContraceptiveQuizCta
