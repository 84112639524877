import { graphql } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import { Button, Col, Container, Row, Table } from "reactstrap"

import { Hero, Layout, Link, Seo } from "../../components"
import GenericContraceptive from "../../images/hero-condoms.svg"
import {
  addMethodToCompare,
  removeFromCompare,
  slugify,
  stripString,
  stripTags,
  transformData,
} from "../../lib/utils"
import BreadCrumb from "../../components/Pages/ContranceptiveMethod/BreadCrumb/BreadCrumb"
import NextLink from "../../components/Pages/ContranceptiveMethod/NextLink/NextLink"
import ContraceptiveQuizCta from "../../components/ContraceptiveQuizCta/ContraceptiveQuizCta"
import CompareTable from "../../components/CompareTable/CompareTable"
import Slider from "../../components/Slider/Slider"
import ContraceptiveMethodCard from "../../components/ContraceptiveMethodCard/ContraceptiveMethodCard"
import ArticleCard from "../../components/ArticleCard/ArticleCard"
import ArrowDropdown from "../../images/arrow_drop_down.svg"
import ArrowUp from "../../images/arrow_drop_down_up.svg"
import HorizontalScroll from "../../components/HorizontalScroll/HorizontalScroll"

const SingleMethodSection = ({ data, pageContext }) => {
  const [showDetails, setShowDetails] = useState(false)
  const [selectedMethodIndex, setSelectedMethodIndex] = useState(null)
  const [mainSelectorOpen, setMainSelectorOpen] = useState(false)
  const [isChecked, setIsChecked] = useState({})
  const [compareMethodsData, setCompareMethodsData] = useState([])
  const [modals, setModals] = useState({
    selectMethodModal: false,
    idealMethodModal: false,
  })

  const sections = data?.wpV2Method?.V2Methods?.sections
  const parentSlug = data?.wpV2Method?.V2Methods?.frontendSlug
  const linkLabel = data?.PageDetails?.edges[0]?.node?.hero?.title

  const nextSectionLink = data?.wpSection?.MethodSections?.nextSection
    ? `/${"birth-control-options"}/${parentSlug}${
        data?.wpSection?.MethodSections?.nextSection[0].MethodSections
          ?.frontendSlug
      }`
    : null

  const nextSectionTitle = data?.wpSection?.MethodSections?.nextSection
    ? data?.wpSection?.MethodSections?.nextSection[0].MethodSections.name
    : null

  const seoTitle = data?.wpSection?.MethodSections?.seoTitle
  const seoDescription = data?.wpSection?.MethodSections?.seoDescription
  const lang = data.wpV2Method.V2Methods.languageCode
  const copyrightMessage = stripTags(
    data?.FooterCopyright?.edges[0]?.node?.content
  )
  const slug = pageContext.sectionSlug
  const faqSlug = slugify(stripString(data?.wpV2Method?.title, 1, "–"))

  const currentPathname = pageContext.breadcrumb.location
  const lastSlashIndex = currentPathname.lastIndexOf("/")
  const currentSectionSlug = currentPathname.substring(lastSlashIndex)

  const labelCons =
    data?.MethodTableTranslations?.V2MethodsStringTranslations?.labelCons
  const labelEffectiveness =
    data?.MethodTableTranslations?.V2MethodsStringTranslations
      ?.labelEffectiveness
  const labelPros =
    data?.MethodTableTranslations?.V2MethodsStringTranslations?.labelPros
  const labelWhatIsIt =
    data?.MethodTableTranslations?.V2MethodsStringTranslations?.labelWhatIsIt

  const toggleDetails = () => setShowDetails(prevState => !prevState)

  const toggleMoreOptions = index => {
    setSelectedMethodIndex(selectedMethodIndex === index ? null : index)
    setMainSelectorOpen(true)
  }

  const toggleIdealMethodModal = () =>
    setModals(prevModals => ({
      ...prevModals,
      idealMethodModal: !prevModals.idealMethodModal,
    }))

  const idealPopupTimer = useCallback(
    time => {
      setTimeout(() => {
        if (!modals.selectMethodModal) {
          toggleIdealMethodModal()
        }
      }, time)
    },
    [modals.selectMethodModal]
  )

  const toggleMethod = method => {
    if (isChecked[method.node.id]) {
      removeFromCompare({ method, methodId: method?.node?.id }, lang)
    } else {
      addMethodToCompare({ method, methodId: method?.node?.id }, lang)
      idealPopupTimer(90000)
      // toggleSelectMethodModal()
    }

    setCompareMethodsData(
      JSON.parse(sessionStorage?.getItem(`compareMethods-${lang}`)) || []
    )
  }

  const handleChooseAsMain = method => {
    const methodIndex = compareMethodsData.findIndex(
      item => item.node.id === method.node.id
    )

    if (methodIndex !== -1) {
      const updatedCompareMethodsData = [
        compareMethodsData[methodIndex],
        ...compareMethodsData.slice(0, methodIndex),
        ...compareMethodsData.slice(methodIndex + 1),
      ]

      setCompareMethodsData(updatedCompareMethodsData)
    }
  }

  useEffect(() => {
    setCompareMethodsData(similarMethods)
  }, [])

  const handleMethodDelete = methodToDelete => {
    const updatedMethodsData = compareMethodsData.filter(
      method => method?.node?.id !== methodToDelete?.node?.id
    )

    setCompareMethodsData(updatedMethodsData)
  }

  const languages = transformData(data?.Languages?.edges)

  const similarMethods = data?.SimilarMethods?.edges
    ? data?.SimilarMethods?.edges.slice(0, 5)
    : null

  return (
    <Layout
      lang={lang}
      languages={languages}
      copyrightMessage={copyrightMessage}
      slug={"/birth-control-options/" + pageContext.frontend_slug + slug}
    >
      <Seo title={seoTitle} description={seoDescription} lang={lang} />

      <Hero
        title={data?.wpSection?.MethodSections?.name}
        /*intro={data?.wpV2Method?.V2Methods?.description}*/
        imageUrl={
          data?.wpV2Method?.V2Methods?.image.sourceUrl || GenericContraceptive
        }
        slug="single-method"
        lang={lang}
        pageContext={pageContext}
        isMethod
      />

      <section className="page-section pb-5 mb-5">
        <Container>
          <Row>
            <Col xs={12} md={5}>
              <aside className="sticky-top">
                <BreadCrumb
                  crumbs={[
                    { label: "Home", path: "/" },
                    {
                      label: linkLabel,
                      path: "/birth-control-options/",
                    },
                    { label: data?.wpV2Method?.V2Methods?.name },
                  ]}
                  lang={lang}
                />

                <p className="h4 mt-5 mb-2 section-heading">In this section</p>

                <ul className="section-links">
                  {sections?.map(section => {
                    const sectionLink = section?.MethodSections?.frontendSlug
                    const title = section?.MethodSections?.name
                    const isSectionActive =
                      currentSectionSlug === section.MethodSections.frontendSlug

                    return (
                      <li key={section?.id} className="section-links__item">
                        <Link
                          to={`/birth-control-options/${parentSlug}${sectionLink}#content`} // TODO
                          className={`section-links__link ${
                            isSectionActive ? "section-links__link-active" : ""
                          }`}
                          lang={lang}
                        >
                          {title}
                        </Link>
                      </li>
                    )
                  })}
                </ul>

                <div
                  onClick={toggleDetails}
                  className="d-flex d-md-none btn p-0 font-capriola justify-content-between align-items-center"
                >
                  <p className="m-0">Key details</p>
                  <img
                    src={showDetails ? ArrowUp : ArrowDropdown}
                    alt="arrow-down"
                  />
                </div>

                {!showDetails && (
                  <ul className="method-criteria">
                    {data?.BirthControlMethodsCriteriaLifestyle?.edges?.map(
                      criteria => (
                        <li
                          key={criteria?.node?.title}
                          className="method-criteria__item"
                        >
                          <div className="method-criteria__content">
                            <img
                              src={
                                criteria?.node?.generic_block_data?.image
                                  ?.sourceUrl
                              }
                              alt={criteria?.node?.title}
                            />
                            <p className="method-criteria__text">
                              {criteria?.node?.title}
                            </p>
                          </div>
                        </li>
                      )
                    )}
                    {/* {data?.wpV2Method?.V2Methods?.methodCriteria?.map(
                      criteria => (
                        <li key={criteria} className="method-criteria__item">
                          <div className="method-criteria__content">
                            <p className="method-criteria__text">{criteria}</p>
                          </div>
                        </li>
                      )
                    )} */}
                  </ul>
                )}

                <Link
                  to="/compare-contraceptive-methods"
                  lang={lang}
                  className="button w-100 my-5 md-my-0"
                >
                  Compare Method
                </Link>
              </aside>
            </Col>
            <Col md={1} xs={0} />
            <Col md={6} xs={12}>
              {/* TODO */}
              <main id="content">
                <span className="h2">{data?.wpSection?.MethodSections?.name}</span>
                <section
                  dangerouslySetInnerHTML={{
                    __html: data?.wpSection?.MethodSections?.details,
                  }}
                />
              </main>

              {nextSectionTitle && (
                <NextLink
                  to={`${nextSectionLink}#content`}
                  label={"Next"}
                  title={nextSectionTitle}
                  lang={lang}
                />
              )}
            </Col>
          </Row>
        </Container>
      </section>

      <ContraceptiveQuizCta />

      <section className="page-section pb-0">
        <Container>
          <Row>
            <Col md={7}>
              <h3>Compare with similar Contraceptive Methods</h3>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>
                Are you wondering if condoms are better than daily pills? Or if
                you should opt for a birth control implant? We're here to assist
                you in making this decision. You can select up to 5
                contraceptive methods and compare them side by side to weigh the
                pros and cons of each.
              </p>
              <p>Give a try to our Contraceptive Tool</p>
            </Col>
            <Col md={6}>
              <p>
                In the example below, you'll find similar methods to the one
                you're currently reading about. Feel free to click on any that
                catch your interest or revisit our Contraceptive Methods page
              </p>
            </Col>
            <Col sm={12}>
              <Link
                to="/compare-contraceptive-methods"
                className="button w-100 my-5 d-block d-md-none"
              >
                Compare Methods
              </Link>
              <Link
                to="/compare-contraceptive-methods"
                className="button w-auto mt-2 mb-5 d-none d-md-inline-flex"
              >
                Compare Methods
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="page-section">
        <Container>
          <Row>
            <Col xs="12">
              <div className="compare-table__holder">
                {compareMethodsData ? (
                  <Table responsive borderless className="compare-table">
                    <tbody>
                      <tr className="compare-table__row">
                        {compareMethodsData?.map((method, index) => {
                          return (
                            <CompareTable
                              key={index}
                              imageUrl={
                                method?.node?.V2Methods?.image?.sourceUrl
                              }
                              index={index}
                              toggleMoreOptions={toggleMoreOptions}
                              selectedMethodIndex={selectedMethodIndex}
                              setSelectedMethodIndex={setSelectedMethodIndex}
                              setMainSelectorOpen={setMainSelectorOpen}
                              handleCheckboxChange={handleMethodDelete}
                              method={method}
                              handleChooseAsMain={handleChooseAsMain}
                              methodLink={method?.node?.V2Methods?.frontendSlug}
                              lang={lang}
                              methodTitle={method?.node?.V2Methods?.name}
                              methodType={method?.node?.V2Methods?.type}
                              whatIsIt={
                                method?.node?.V2Methods?.shortDescription
                              }
                              efficacy={method?.node?.V2Methods?.effectiveness}
                              pros={method?.node?.V2Methods?.pros}
                              cons={method?.node?.V2Methods?.cons}
                              labelCons={labelCons}
                              labelEffectiveness={labelEffectiveness}
                              labelPros={labelPros}
                              labelWhatIsIt={labelWhatIsIt}
                            />
                          )
                        })}
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="page-section pb-0">
        <Container>
          <h3 className="m-0">Need to learn more things?</h3>
          <p className="mb-5">Read about other Contraceptive methods</p>

          <div className="d-md-none">
            <HorizontalScroll>
              <ContraceptiveMethodCard
                imgFile={GenericContraceptive}
                heading={"See All Methods"}
                subHeading={"Search my best contraceptive method."}
                link={"/birth-control-options/"}
              />
              <ContraceptiveMethodCard
                imgFile={
                  data?.wpV2Method?.V2Methods?.image.sourceUrl ||
                  GenericContraceptive
                }
                heading={`${data?.wpV2Method?.V2Methods?.name} FAQ's`}
                subHeading={`Have some questions about ${data?.wpV2Method?.V2Methods?.name}?`}
                link={`/frequently-asked-questions/`}
                lang={lang}
              />
            </HorizontalScroll>
          </div>

          <div className="d-md-block d-none">
            <Slider itemsToShow={2}>
              <ContraceptiveMethodCard
                imgFile={GenericContraceptive}
                heading={"See All Methods"}
                subHeading={"Search my best contraceptive method."}
                link={"/birth-control-options/"}
              />
              <ContraceptiveMethodCard
                imgFile={
                  data?.wpV2Method?.V2Methods?.image.sourceUrl ||
                  GenericContraceptive
                }
                heading={`${data?.wpV2Method?.V2Methods?.name} FAQ's`}
                subHeading={`Have some questions about ${data?.wpV2Method?.V2Methods?.name}?`}
                link={`/frequently-asked-questions/`}
                lang={lang}
              />
            </Slider>
          </div>
        </Container>
      </section>

      <section className="page-section pb-0 mb-5">
        <Container>
          <h3 className="my-5">Our Monthly Top Articles</h3>

          <div className="d-md-block d-none">
            <Slider itemsToShow={2}>
              {data?.HomePopularBlog?.nodes?.length !== 0
                ? data?.HomePopularBlog?.nodes?.map((article, idx) => {
                    const imageUrl =
                      article?.featuredImage?.node?.localFile?.childImageSharp
                        ?.fluid?.src
                    const title = article.title
                    const excerpt = article.excerpt
                    const url = article.fmmCore?.frontendSlug
                    const lang = article?.fmmCore?.languageCode
                    return (
                      <ArticleCard
                        key={idx}
                        imgUrl={imageUrl}
                        title={title}
                        excerpt={excerpt}
                        url={url}
                        lang={lang}
                      />
                    )
                  })
                : null}
            </Slider>
          </div>

          <div className="d-md-none">
            <HorizontalScroll itemsToShow={2}>
              {data?.HomePopularBlog?.nodes?.length !== 0
                ? data?.HomePopularBlog?.nodes?.map((article, idx) => {
                    const imageUrl =
                      article?.featuredImage?.node?.localFile?.childImageSharp
                        ?.fluid?.src
                    const title = article.title
                    const excerpt = article.excerpt
                    const url = article.fmmCore?.frontendSlug
                    const lang = article?.fmmCore?.languageCode
                    return (
                      <ArticleCard
                        key={idx}
                        imgUrl={imageUrl}
                        title={title}
                        excerpt={excerpt}
                        url={url}
                        lang={lang}
                      />
                    )
                  })
                : null}
            </HorizontalScroll>
          </div>

          <div className="d-flex justify-content-center my-5">
            <Link to="/blog/" lang={lang} className="button">
              See all articles
            </Link>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default SingleMethodSection

export const query = graphql`
  query (
    $frontend_slug: String!
    $language_code: String!
    $sectionSlug: String!
    $sectionId: String!
    $methodType: String!
  ) {
    wpV2Method: wpV2Method(
      V2Methods: {
        frontendSlug: { eq: $frontend_slug }
        languageCode: { eq: $language_code }
      }
    ) {
      date
      V2Methods {
        description
        effectiveness
        languageCode
        frontendSlug
        name
        methodCriteria
        pros {
          fieldGroupName
          proField
        }
        type
        shortDescription
        sections {
          ... on WpSection {
            id
            slug
            status
            title
            uri
            template {
              templateName
            }
            nodeType
            link
            MethodSections {
              frontendSlug
              languageCode
              name
            }
          }
        }
        image {
          altText
          uri
          title
          fileSize
          sourceUrl
          srcSet
        }
        cons {
          conField
        }
      }
      slug
      template {
        templateName
      }
      title
      uri
      modified
      link
      id
    }

    PageDetails: allWpPage(
      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: "/birth-control-options/" }
        }
      }
    ) {
      edges {
        node {
          id
          hero {
            title
          }
        }
      }
    }

    wpSection(
      MethodSections: {
        frontendSlug: { eq: $sectionSlug }
        languageCode: { eq: $language_code }
      }
      id: { eq: $sectionId }
    ) {
      MethodSections {
        seoDescription
        name
        details
        seoTitle
        nextSection {
          ... on WpSection {
            id
            title
            link
            MethodSections {
              frontendSlug
              languageCode
              name
            }
          }
        }
      }
    }

    MethodTableTranslations: wpV2Method(
      V2Methods: { languageCode: { eq: $language_code } }
    ) {
      V2MethodsStringTranslations {
        labelCons
        labelWhatIsIt
        labelPros
        labelEffectiveness
      }
    }

    SimilarMethods: allWpV2Method(
      filter: {
        V2Methods: {
          languageCode: { eq: $language_code }
          type: { eq: $methodType }
        }
      }
    ) {
      edges {
        node {
          id
          slug
          title
          V2Methods {
            languageCode
            name
            shortDescription
            frontendSlug
            methodCriteria
            type
            effectiveness
            cons {
              conField
            }
            pros {
              proField
            }
            image {
              sourceUrl
            }
          }
        }
      }
    }

    HomePopularBlog: allWpPost(
      filter: { fmmCore: { languageCode: { eq: $language_code } } }
      sort: { fields: date, order: DESC }
      limit: 3
    ) {
      nodes {
        slug
        title
        date(formatString: "DD  MMMM, YYYY")
        excerpt
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(maxWidth: 650, maxHeight: 300, quality: 100, fit: COVER) {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            }
          }
        }
        fmmCore {
          frontendSlug
          languageCode
        }
      }
    }

    Languages: allWpV2Method(
      filter: { V2Methods: { frontendSlug: { eq: $frontend_slug } } }
    ) {
      edges {
        node {
          id
          V2Methods {
            languageCode
            frontendSlug
          }
        }
      }
    }

    BirthControlMethodsCriteriaLifestyle: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }

      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: "/birth-control-options/" }
        }
        generic_block_data: {
          type: { eq: "BirthControlMethodsCriteriaLifestyle" }
        }
      }
    ) {
      edges {
        node {
          title
          generic_block_data {
            priorityOrder
            linkLabel
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }

    BirthControlMethodsCriteriaMedical: allWpGenericBlock(
      sort: { fields: generic_block_data___priorityOrder }

      filter: {
        fmmCore: {
          languageCode: { eq: $language_code }
          frontendSlug: { eq: "/birth-control-options/" }
        }
        generic_block_data: {
          type: { eq: "BirthControlMethodsCriteriaMedical" }
        }
      }
    ) {
      edges {
        node {
          title
          generic_block_data {
            priorityOrder
            linkLabel
            image {
              sourceUrl
              srcSet
            }
          }
        }
      }
    }

    FooterCopyright: allWpGenericBlock(
      filter: {
        fmmCore: { languageCode: { eq: $language_code } }
        generic_block_data: { type: { eq: "FooterCopyright" } }
      }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
