import React from "react"
import ClickAwayListener from "react-click-away-listener"

import "./CompareTable.scss"
import Link from "../Link/Link"
import removeIcon from "../../images/compare-remove.svg"

const CompareTable = ({
  index,
  imageUrl,
  toggleMoreOptions,
  selectedMethodIndex,
  setSelectedMethodIndex,
  setMainSelectorOpen,
  handleCheckboxChange,
  method,
  handleChooseAsMain,
  methodLink,
  lang,
  methodTitle,
  methodType,
  whatIsIt,
  efficacy,
  pros,
  cons,
  labelCons,
  labelEffectiveness,
  labelPros,
  labelWhatIsIt,
}) => {
  return (
    <>
      <td className="compare-table__cell">
        <figure className="compare-table__figure">
          <div className="compare-table__image-holder">
            <img src={imageUrl} alt="" width="250px" loading="lazy" />
            <div
              className="compare-table-options"
              onClick={() => toggleMoreOptions(index)}
            />

            {selectedMethodIndex === index && (
              <ClickAwayListener
                onClickAway={event => {
                  setSelectedMethodIndex(null)
                  setMainSelectorOpen(false)
                }}
              >
                <div className="compare-table__main-selector">
                  <div
                    onClick={() => {
                      handleCheckboxChange(method)
                      setSelectedMethodIndex(null)
                      setMainSelectorOpen(false)
                    }}
                    className="compare-table__main-selector--delete-container"
                  >
                    <span>Delete</span>
                    <img src={removeIcon} alt="trash" />
                  </div>
                  <div
                    onClick={() => {
                      handleChooseAsMain(method)
                      setSelectedMethodIndex(null)
                      setMainSelectorOpen(false)
                    }}
                    className="compare-table__main-selector--action-container"
                  >
                    Choose this method as main
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>

          <section className="compare-table__info-section">
            <section>
              <Link
                to={`/birth-control-options/${methodLink}/`}
                lang={lang}
                className="compare-table__name-link"
              >
                {methodTitle}
              </Link>
              <p className="compare-table__hormones">{methodType}</p>
            </section>
          </section>
        </figure>
        <div className="compare-table__heading color-orange">
          {index === 0 && <strong>{labelWhatIsIt}</strong>}
        </div>
        <div className="compare-table__whatisit">{whatIsIt}</div>
        <div className="compare-table__heading color-green">
          {index === 0 && <strong>{labelEffectiveness}</strong>}
        </div>
        <div className="compare-table__efficacy">{efficacy}%</div>
        <div className="compare-table__heading color-blue">
          {index === 0 && <strong>{labelPros}</strong>}
        </div>
        <div className="compare-table__pros">
          {pros.map((pro, idx) => {
            return <li key={idx}>{pro.proField}</li>
          })}
        </div>
        <div className="compare-table__heading color-warn">
          {index === 0 && <strong>{labelCons}</strong>}
        </div>
        <div className="compare-table__cons">
          {cons.map((con, idx) => {
            return <li key={idx}>{con.conField}</li>
          })}{" "}
        </div>
      </td>
    </>
  )
}

export default CompareTable
